
.height-fill {
  height: 100%;
}

.flex {
  display: flex !important;
  &.align-items- {
    &stretch {
      align-items: stretch;
    }
    &start {
      align-items: flex-start;
    }
    &end {
      align-items: flex-end;
    }
    &center {
      align-items: center;
    }
    &baseline {
      align-items: baseline;
    }
  }
  &.justify-content- {
    &around {
      justify-content: space-around;
    }
    &evenly {
      justify-content: space-evenly;
    }
    &between {
      justify-content: space-between;
    }
    &start {
      justify-content: flex-start;
    }
    &end {
      justify-content: flex-end;
    }
    &center {
      justify-content: center;
    }
  }
  &.flex-direction- {
    &row {
      flex-direction: row;
    }
    &column {
      flex-direction: column;
    }
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
}

.display- {
  &inline {
    display: inline;
  }
  &inline-block {
    display: inline-block;
  }
  &block {
    display: block;
  }
  &table {
    display: table;
  }
}
