
.box-shadow- {
  &pale {
    box-shadow: 0 1px 19px 1px rgba(0, 0, 0, 0.16);
  }
  &light {
    box-shadow: 0 2px 7px 0px rgba(0, 0, 0, 0.56);
  }
  &medium {
    box-shadow: 0 5px 17px 1px rgba(0, 0, 0, 0.56);
  }
  &heavy {
    box-shadow: 0 10px 35px 2px rgba(0, 0, 0, 0.56);
  }
  &intense {
    box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
  }
}

.f-box-shadow- {
  &pale {
    box-shadow: 0 1px 19px 1px rgba(0, 0, 0, 0.16) !important;
  }
  &pale-transition {
    box-shadow: 0 1px 19px 1px rgba(0, 0, 0, 0.16) !important;
    transition: all 0.3s ease-out !important;
    &:hover {
      box-shadow: 0 3px 19px 1px rgba(0, 0, 0, 0.36) !important;
      transform: scale(1.05) !important;
    }
  }
  &light {
    box-shadow: 0 2px 7px 0px rgba(0, 0, 0, 0.56) !important;
  }
  &medium {
    box-shadow: 0 5px 17px 1px rgba(0, 0, 0, 0.56) !important;
  }
  &heavy {
    box-shadow: 0 10px 35px 2px rgba(0, 0, 0, 0.56) !important;
  }
  &intense {
    box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56) !important;
  }
}





.no-box-shadow {
  box-shadow: none;
}
.f-no-box-shadow {
  box-shadow: none !important;
}
