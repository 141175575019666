

/* Disabled link styles */
a, a:link, a:visited, a:hover, a:focus, a:active {
  &.no-link {
    color: inherit;
    text-decoration: none;
  }
  &.f-no-link {
    color: inherit !important;
    text-decoration: none !important;
  }
}