
/**
 * Fix for semantic ui sidebar: font-size
 * Used to override default font sice for icon in sidebar
 */
.ui.labeled.icon.menu .item > i.sidebar.icon.f-font-xl-override  {
  font-size: 2rem !important;
}

/**
 * fix for semantic ui sidebar: removes scrollbar when open
 */
.pushable {
  overflow-x: inherit;
}

/**
 * fix for semantic ui sidebar: makes it stick to top
 * (changes the sidebar back to Page Context)
 */
.pushable:not(body) {
  transform: none;
  & > .ui.sidebar,
  & > .fixed,
  & > .pusher:after {
    position: fixed;
  }
}

/* to make a mobile responsive view of the referral banner */
@media only screen and (max-width: 600px) {
  .referral-banner--image {
    margin-bottom: -50px !important;
  }
}

.header-appointment {
  padding-top: 20px;
  margin-bottom: -20px;
}

