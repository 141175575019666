/* 
  Image Modifiers
    -> https://www.w3schools.com/css/css3_images.asp
    -> https://www.w3schools.com/css/tryit.asp?filename=trycss_ex_images_filters
*/

.img {
  &.blur {
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }

  &.brightness {
    -webkit-filter: brightness(250%);
    filter: brightness(250%);
  }

  &.contrast {
    -webkit-filter: contrast(180%);
    filter: contrast(180%);
  }

  &.grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  &.huerotate {
    -webkit-filter: hue-rotate(180deg);
    filter: hue-rotate(180deg);
  }

  &.invert {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  &.opacity {
    -webkit-filter: opacity(50%);
    filter: opacity(50%);
  }

  &.saturate {
    -webkit-filter: saturate(7);
    filter: saturate(7);
  }

  &.sepia {
    -webkit-filter: sepia(100%);
    filter: sepia(100%);
  }

  &.shadow {
    -webkit-filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.5));
  }
}

.img-fix-height {
  height: 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover
}


$bannerHeight: 102vh;
$imageUrlBase: 'https://ik.imagekit.io/dydactly/ptg-westchester/';

// $imageUrlBase: 'https://ik.imagekit.io/dydactly/ptg-westchester/father-holding-child-beach__compressed.jpg';
// https://ik.imagekit.io/dydactly/ptg-westchester/father-holding-child-beach__mobile.jpg
@mixin bannerImage($imageName) {
  .banner-image-#{$imageName} {
    position: relative;
    z-index: 1;
    height: $bannerHeight;

    &::before {
      background-image: url($imageUrlBase + $imageName + '__compressed.jpg?tr=w-1920,h-1080');
      -webkit-background-size: cover !important;
      -moz-background-size: cover !important;
      -o-background-size: cover !important;
      background-size: cover !important;
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      height: $bannerHeight;
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // opacity: .4;
      // filter: grayscale(100%);
      // filter: blur(1px);
      filter: sepia(40%);
      // filter: brightness(120%);
    }
  }

  @media only screen and (max-width: 1024px) {
    .banner-image-#{$imageName} {
      &::before {
        background-position: right -100px bottom 0px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .banner-image-#{$imageName} {
      &::before {
        background-position: right -130px bottom 0px;
        background-attachment: scroll;
      }
    }
  }
}

@mixin bannerImageOpaque($imageName) {
  .banner-image-#{$imageName} {
    position: relative;
    z-index: 1;
    height: $bannerHeight;

    &::before {
      background-image: url('../../assets/images/' + $imageName + '__compressed.jpg') !important;
      -webkit-background-size: cover !important;
      -moz-background-size: cover !important;
      -o-background-size: cover !important;
      background-size: cover !important;
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      height: $bannerHeight;
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // opacity: .4;
      // filter: grayscale(100%);
      // filter: blur(1px);
      filter: brightness(80%) sepia(40%);
    }
  }

  @media only screen and (max-width: 1024px) {
    .banner-image-#{$imageName} {
      &::before {
        background-position: right -100px bottom 0px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .banner-image-#{$imageName} {
      &::before {
        background-position: right -130px bottom 0px;
        background-attachment: scroll;
      }
    }
  }
}

@mixin bannerBackgroundColor($colorName, $color) {
  .banner-background-#{$colorName} {
    position: relative;
    // z-index: 1;
    height: 40vh;

    &::before {
      height: 40vh;
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color;
      // background-image: linear-gradient($color, white);
      // opacity: .8;
      // filter: grayscale(100%);
      // filter: blur(1px);
      // filter: sepia(100%);
      // filter: brightness(120%);
    }
  }
}

@include bannerBackgroundColor('bannerPrimary', $primaryColor);
@include bannerBackgroundColor('bannerSecondary', $secondaryColor);
@include bannerBackgroundColor('bannerPrimaryLight', $primaryColorLight);
// @include bannerBackgroundColor('bannerPrime', $celestialBlue);


// @include bannerImage('dry-bark');
@include bannerImage('father-holding-child-beach');
// @include bannerImage('hiking-couple');
// @include bannerImage('parents-daughter-beach');
// @include bannerImage('parents-at-mountaintop');
// @include bannerImage('gray-dry-trunk');
// @include bannerImage('beach-sand-waves-rock');
// @include bannerImage('beach-sand-wavy');
// @include bannerImage('mountains-riverside');
// @include bannerImage('group-picture');
// @include bannerImage('doctor-holding-hands');
@include bannerImageOpaque('person-with-laptop');
