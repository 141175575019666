
.red-border {border: 1px solid red;}
.blue-border {border: 1px solid blue;}

.no-border {
  border: none;
}
.f-no-border {
  border: none !important;
}
