

@for $i from 0 through 20 {
  $value: 5 * $i;
  .padding- {
    &#{$value} {
      padding: $value + px;
    }
    &top-#{$value} {
      padding-top: $value + px;
    }
    &bottom-#{$value} {
      padding-bottom: $value + px;
    }
    &top-bottom-#{$value} {
      padding-top: $value + px;
      padding-bottom: $value + px;
    }
    &left-#{$value} {
      padding-left: $value + px;
    }
    &right-#{$value} {
      padding-right: $value + px;
    }
    &left-right-#{$value} {
      padding-left: $value + px;
      padding-right: $value + px;
    }
  }
  .f-padding- {
    &#{$value} {
      padding: $value + px !important;
    }
    &top-#{$value} {
      padding-top: $value + px !important;
    }
    &bottom-#{$value} {
      padding-bottom: $value + px !important;
    }
    &top-bottom-#{$value} {
      padding-top: $value + px !important;
      padding-bottom: $value + px !important;
    }
    &left-#{$value} {
      padding-left: $value + px !important;
    }
    &right-#{$value} {
      padding-right: $value + px !important;
    }
    &left-right-#{$value} {
      padding-left: $value + px !important;
      padding-right: $value + px !important;
    }
  }
}
